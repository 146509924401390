import { Box } from '@chakra-ui/react';
import React from 'react';

const CustomBtn = ({ title }) => {
  return (
    <Box
      bg={title === 'Login' ? 'white' : 'blue'}
      color={title === 'Login' ? 'black' : 'white'}
      rounded="5px"
      py={{ base: '8px', lg: '4px' }}
      px={title === 'Login' ? '50px' : '40px'}
      border="1px solid  #2F2F2F"
      fontSize="23px"
      fontWeight={{ base: '600', lg: '500' }}
    >
      {title}
    </Box>
  );
};

export default CustomBtn;
