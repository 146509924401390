import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../apiPaths';
import CreativeItem from '../Components/FromSidebar/Student/CreativesList/CreativeItem';
import bgImage from '../assests/screenartwork1.png';

import {
  Box,
  Flex,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Heading,
  Img,
} from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assests/Logo.png';

import CustomBtn from '../Components/sharedComponent/CustomBtn';

export default function Home() {
  const [userData, setUserData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const navigate = useNavigate();

  async function fetchData(q) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/user/tutors/get?q=${q}`,
        {}
      );
      const users = await response.json();
      setUserData(users.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (currentUser && currentUser.roleName == 'TUTOR') {
      localStorage.setItem('selectedButton', 'calendar');
      navigate('/calendar');
    }
    if (currentUser && currentUser.roleName == 'STUDENT') {
      localStorage.setItem('selectedButton', 'dashboard');
      navigate('/tutor-search');
    }
    const element = document.querySelector('body');
    const input = document.querySelector('body .css-1ngklx');
    if (element) {
      element.style.backgroundColor = '#F2F2F2';
      element.style.color = 'black';
    }
  }, []);

  const handleChange = (event) => {
    setIsFetch(true);
    fetchData(event.target.value);
  };

  return (
    <Box
      background={`url(${bgImage})`}
      backgroundSize={{ base: 'cover', lg: 'cover' }}
      backgroundRepeat="no-repeat"
      backgroundPosition={{ base: 'right', lg: 'top' }}
      display={{ lg: 'flex' }}
      flexDirection={{ base: 'column-reverse', lg: 'column' }}
      height={{ base: '100vh', lg: '100vh' }}
      py={{ base: '1rem', lg: '.5rem' }}
      alignItems={'center'}
      overflowY={'hidden'}
    >
      <Flex
        justify={{ lg: 'end' }}
        mx={{ base: 'auto' }}
        w={{ base: '90%', lg: '98%' }}
      >
        <Box
          w={'100%'}
          display={{ lg: 'flex' }}
          justifyContent={{ base: 'center', lg: 'end' }}
        >
          {!currentUser && (
            <Box>
              <Box gap={'10'} pt={'10'} display={{ base: 'none', lg: 'flex' }}>
                <Box>
                  <Link to={`/register`}>
                    <CustomBtn title={'Sign Up'} pathname={pathname} />
                  </Link>
                </Box>
                <Box display={{ base: 'none', lg: 'block' }}>
                  <Link to={`/login`}>
                    <CustomBtn title={'Login'} pathname={pathname} />
                  </Link>
                </Box>
              </Box>
            </Box>
          )}
          {currentUser && (
            <Button
              bg="white"
              _hover={{
                bg: 'black',
                color: 'white',
              }}
              color="blue"
              rounded="40px"
              py="10px"
              px="20px"
              mx="10px"
              border="1px solid  #fff"
              fontSize="14px"
              fontWeight="600"
              onClick={() => {
                window.localStorage.removeItem('currentUser');
                localStorage.removeItem('sessionRequestFormData');
                localStorage.removeItem('view');
                navigate('/login');
              }}
            >
              LOG OUT
            </Button>
          )}
        </Box>
      </Flex>

      <Box
        width={{ base: '84%', md: '95%' }}
        height={'100vh'}
        display={'flex'}
        justifyContent="start"
        ml={{ lg: 'auto' }}
        mx={{ base: 'auto' }}
        alignItems={{ base: 'center', lg: 'center' }}
      >
        <Box py="auto" textAlign="center" color="#fff">
          <Heading fontSize={{ base: '18px', md: '27px' }} fontWeight="500">
            START YOUR CREATIVE JOURNEY WITH
          </Heading>
          <Img
            src={logo}
            h={{ base: '10rem', lg: '14rem' }}
            w={{ base: '23rem', lg: '32rem' }}
            my={'7'}
          />
          <Box>
            <InputGroup
              borderRadius={5}
              size="sm"
              width="100%"
              mx="auto"
              mb={10}
            >
              <Input
                type="text"
                placeholder="Search"
                onChange={handleChange}
                background="#fff"
                padding="25px 10px"
                rounded={'10'}
                color="#000"
                sx={{
                  '::placeholder': {
                    color: '#000',
                    fontSize: '1.2rem',
                    fontWeight: '600',
                  },
                }}
              />
              <InputRightElement
                pointerEvents="none"
                padding="25px 20px"
                fontSize="18px"
                children={<Search2Icon color="blue" h={6} w={6} />}
              />
            </InputGroup>
          </Box>
          <Box w={'100%'} display={{ base: 'block', lg: 'none' }}>
            <Box w={'100%'}>
              <Link to={`/login`} style={{ width: '100%' }}>
                <CustomBtn title={'Login'} pathname={pathname} />
              </Link>
            </Box>
            <Box w={'100%'} mt={"0.5rem"}>
              <Link to={`/register`} style={{ width: '100%' }}>
                <CustomBtn title={'Sign Up'} pathname={pathname} />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      {userData?.map((user) => (
        <Box
          style={{
            marginBottom: '10px',
            color: '#000',
          }}
        >
          <CreativeItem key={user._id} data={user} />
        </Box>
      ))}
      {userData.length < 1 && isFetch && (
        <Box textAlign="center">Records not found.</Box>
      )}
    </Box>
  );
}
