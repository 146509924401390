import { Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const NavItem = ({ path, title, pathname, onClick }) => {
  return (
    <Link to={path} onClick={onClick}>
      <Button
        my={'.4rem'}
        bg={pathname === path ? '#0033FF' : 'black'}
        color={'white'}
        rounded="10px"
        w="100%"
        py="1.6rem"
        fontSize="18px"
        fontWeight="500"
      >
        {title}
      </Button>
    </Link>
  );
};

export default NavItem;
