import { Avatar, Box, Button, Flex, Img, Text } from '@chakra-ui/react';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { USER_IMAGE_URL } from '../apiPaths';
import { NavLinks } from '../utils/contants';
import NavItem from '../Components/sharedComponent/NavItem';
import logo from '../assests/Logo.png';
import { CloseIcon } from '@chakra-ui/icons';
const SidebarTwo = ({ handleToggle }) => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const handleLogout = () => {
    window.localStorage.removeItem('currentUser');
    localStorage.removeItem('sessionRequestFormData');
    localStorage.removeItem('view');
    localStorage.removeItem('selectedButton');
    navigate('/');
  };

  const isTutor = currentUser?.roleName === 'TUTOR';

  const studentNavLinks = [
    { path: '/tutor-search', title: 'Tutor Search' },
    { path: '/creativeslist', title: 'Creatives List' },
    { path: '/mybookings', title: 'My Bookings' },
    { path: '/my-requests', title: 'My Requests' },
  ];

  const tutorNavLinks = [
    { path: '/calendar', title: 'Calendar' },
    { path: '/yourservices', title: 'My Services' },
    { path: '/upcomingsessions', title: 'My Bookings' },
    { path: '/my-requests', title: 'My Requests' },
    { path: '/ratesetting', title: 'Settings' },
  ];

  const NavLinks = isTutor ? tutorNavLinks : studentNavLinks;

  return (
    <Box
      as="nav"
      pt={'2rem'}
      backgroundColor="#1D1D1D"
      overflowX="hidden"
      overflowY="auto"
      bgSize="cover"
      bgPosition="center"
      height={
        pathname === '/ratesetting' || pathname === '/calendar'
          ? ' 100vh'
          : '100%'
      }
      justifyItems={'space-between '}
      px="40px"
      display={'flex'}
      flexDir={'column'}
      justifyContent={'space-between'}
      pb="2rem"
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        justifyItems={'space-between'}
      >
        <Box display={'flex '} justifyContent={'space-between'} width={'100%'}>
          <Img height={'4rem'} src={logo} />{' '}
          <div
            onClick={handleToggle}
            style={{ position: 'relative', left: '1rem', cursor: 'pointer' }}
          >
            <CloseIcon size={35} display={{ base: 'block', lg: 'none' }} />
          </div>
        </Box>
        <Flex
          mt="30px"
          justifyContent={'center'}
          alignItems="center"
          gap="15px"
        >
          <Button background="none" padding="0" as={Link} to="/profile">
            <Box>
              <Avatar
                width="3.3rem"
                height="3.3rem"
                name={currentUser?.displayName || 'A'}
                src={
                  currentUser?.profile
                    ? USER_IMAGE_URL + currentUser?.profile
                    : 'https://avatars2.githubusercontent.com/u/37842853?v=4'
                }
                cursor="pointer"
                crossOrigin="annonymous"
              />
            </Box>
          </Button>
          <Flex flexDirection="column">
            <Text fontWeight="700" fontSize="18px" color="white">
              {currentUser?.displayName
                ? currentUser?.displayName
                : 'user name'}
            </Text>
            <Link to="/profile">
              <Text
                fontWeight="600"
                fontSize="15px"
                color="white"
                _hover={{
                  textDecoration: 'underline',
                }}
              >
                Edit
              </Text>
            </Link>
          </Flex>
        </Flex>
        <Box mt={'2rem'}>
          {NavLinks.map((item) => {
            return (
              <NavItem
                onClick={handleToggle}
                path={item.path}
                title={item.title}
                pathname={pathname}
              />
            );
          })}
        </Box>
      </Box>
      <Box>
        <Button
          bg="#0033ff"
          rounded="10px"
          w="100%"
          py="1rem"
          border="1px solid  #2F2F2F"
          fontSize="18px"
          fontWeight="600"
          onClick={handleLogout}
        >
          Log out
        </Button>
      </Box>
    </Box>
  );
};

export default SidebarTwo;
