import React, { useState, useEffect } from "react";
import MyRequestHeader from "../Components/Headers/MyRequestHeader.jsx";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Flex, Box, Text, Image } from "@chakra-ui/react";
import { API_BASE_URL } from "../apiPaths.js";
import MySessionRequestList from "../Components/Forms/MyRequest/MySessionRequestList.jsx";
import RequestForm from "../Components/Forms/MyRequest/RequestForm.jsx";

export default function MyRequest() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [receivedRequests, setReceivedRequest] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [isAcceptButtonClicked, setIsAcceptButtonClicked] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  function handleCreateRequest(val) {
    setShowRequestForm(val);
    setSelectedRequestId(null); 
  }

  const handleAccept = (requestId, isAccept) => {
    setShowRequestForm(true);
    setSelectedRequestId(requestId);
    setIsAcceptButtonClicked(isAccept);
  };

  function handleModifyRequest(id) {
    setShowRequestForm(true);
    setSelectedRequestId(id);
  }

  function handleFormClose(val) {
    setShowRequestForm(val);
  }

  async function fetchMyRequest() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/session-request/${currentUser?.id}`,
        {
          headers: {
            "x-access-token": currentUser.token,
          },
        }
      );
      const res = await response.json();
      const receivedRequests =
      res.data?.receivedRequests[0]?.data.length > 0
        ? res.data?.receivedRequests[0]?.data.map((request) => (
            <Box key={request._id} position={"relative"}>
              <MySessionRequestList
                data={request}
                key={request._id}
                onModifyRequest={() => handleModifyRequest(request._id)}
                onAccept={handleAccept}
              />
            </Box>
          ))
        : [
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgColor={"#fffA6"}
              flexDirection={"column"}
              rounded="8px"
              height="400px"
              p={{ base: 5, lg: 5 }}
              boxShadow={"md"}
              m={{ base: 2, lg: 1 }}>
                No Received Requests            
            </Box>
          ];
    

        const sentRequests =
        res.data?.sentRequests[0]?.data.length > 0
          ? res.data?.sentRequests[0]?.data.map((request) => (
              <Box key={request._id} position={"relative"}>
                <MySessionRequestList
                  data={request}
                  key={request._id}
                  onModifyRequest={() => handleModifyRequest(request._id)}
                  onAccept={handleAccept}
                />
              </Box>
            ))
          : [
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgColor={"#fffA6"}
              flexDirection={"column"}
              rounded="8px"
              height="400px"
              p={{ base: 5, lg: 5 }}
              boxShadow={"md"}
              m={{ base: 2, lg: 1 }}>
                No Sent Requests            
            </Box>
            ];
      

      setReceivedRequest(receivedRequests);
      setSentRequests(sentRequests);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchMyRequest();
  }, []);
  return (
    <>
      <MyRequestHeader title="My Session Request" onCreateRequest={handleCreateRequest}/>
        <Box width={"88%"} mx={"auto"} my={5} position={"relative"}>
          <Box mt={5}>
            <Text
              mt={"30px"}
              mb="10px"
              fontWeight="600"
              fontSize={{ base: "20px", md: "20px" }}>
              Received Requests
            </Text>
            <AliceCarousel
              mouseTracking
              items={receivedRequests} 
              responsive={responsive}
              controlsStrategy="alternate"
              infinite={false}
              disableDotsControls={true}
              renderPrevButton={() => (
                <button type="button" data-role="none" className="slick-arrow slick-prev" style={{ display: "block" }}>
                  Previous
                </button>
              )}
              renderNextButton={() => (
                <button type="button" data-role="none" className="slick-arrow slick-next" style={{ display: "block" }}>
                  Next
                </button>
              )}
            />
          </Box>
        </Box>
        <Box width={"88%"} mx={"auto"} my={5} position={"relative"}>
          <Box mt={5}>
            <Text
              mt={"30px"}
              mb="20px"
              fontWeight="600"
              color={"white"}
              fontSize={{ base: "20px", md: "20px" }}>
              Sent Requests
            </Text>
            <AliceCarousel
              mouseTracking
              items={sentRequests} 
              responsive={responsive}
              controlsStrategy="alternate"
              infinite={false}
              disableDotsControls={true}
              renderPrevButton={() => (
                <button type="button" data-role="none" className="slick-arrow slick-prev" style={{ display: "block" }}>
                  Previous
                </button>
              )}
              renderNextButton={() => (
                <button type="button" data-role="none" className="slick-arrow slick-next" style={{ display: "block" }}>
                  Next
                </button>
              )}
            />
          </Box>
        </Box>
        {showRequestForm && (
        <RequestForm onFormClose={handleFormClose} isAccept={isAcceptButtonClicked} fetchMyRequest={fetchMyRequest} tutorId={selectedRequestId ? currentUser.id : undefined} requestId={selectedRequestId} onDeleteSuccess={fetchMyRequest}/>
      )}
    </>
  );
}
