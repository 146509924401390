import { useState } from 'react';
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  InputRightElement,
  Box,
  Img,
  Text
} from '@chakra-ui/react';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import bgImage from '../../../assests/22.png';
import logo from '../../../assests/Logo.png';
const signupSchema = yup.object().shape({
  email: yup.string().email('email is invalid').required('email is required'),
  password: yup
    .string()
    .min(6, 'password must be at least 6 characters')
    .required('password is required'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'passwords must match')
    .required('password confirmation is required'),
});
const RegisterDetails = ({
  handleNext,
  handlePrevious,
  isLoading,
  setIsLoading,
  setFormData,
  setIsTutor,
}) => {
  const [show, setShow] = useState(false);
  const [registerType, setRegisterType] = useState('TUTOR');

  const handleClick = () => setShow(!show);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(signupSchema) });

  const onSubmit = async (data) => {
    setIsLoading(true);
    data.registerType = registerType;
    setFormData(data);
    setIsLoading(false);
    registerType == 'TUTOR' ? setIsTutor(true) : setIsTutor(false);
    handleNext();
  };

  return (
    <Box
      position="relative"
      background={`url(${bgImage})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition={'start'}
      height={'100vh'}
      // display={'flex'}
      // flexDirection={'column'}
      // justifyContent={'space-between'}
    >
      <Box color="#fff" fontWeight="600" width={'90%'} mx={'auto'} pt={'10'}>
        <Img
          src={logo}
          h={{ base: '4rem', lg: '5rem' }}
          w={{ base: '10rem', lg: '15rem' }}
        />
      </Box>
      <Center w="90%" mx={{ base: 'auto' }} justifyContent={'end'}>
        <Stack minW={{ base: '100%', md: '640px' }} height="80vh">
          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            position="relative"
            borderTopRadius={{ base: '16px', md: 'none' }}
            rounded={{ base: '', md: 'lg' }}
            px={{ base: '15px', md: '120px' }}
            marginTop= "auto"
            // py={{ base: '40px', md: '60px' }}
          >
            <Stack align="center">
              <Heading fontSize={'30px'} fontWeight="600" color="white">
                Create an account
              </Heading>
              <Box color="white" fontSize={'20px'}>
                Your details
              </Box>
            </Stack>

            <Box w="100%" rounded="10px" mt={'2'} display={'flex'}>
              <Button
                variant="unstyled"
                w={'100%'}
                py={'1.9rem'}
                px={'3rem'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                bg={registerType === 'student' ? 'blue' : 'black'}
                color={registerType === 'student' ? 'white' : 'white'}
                rounded={
                  registerType === 'student'
                    ? '10px 0px 0px 10px'
                    : '10px 0px 0px 10px'
                }
                transition="all"
                transitionDuration="0.5s"
                fontSize={'22px'}
                fontWeight={700}
                onClick={() => {
                  setRegisterType('student');
                }}
              >
                Student
              </Button>
              <Button
                w={'100%'}
                variant="unstyled"
                py={'1.9rem'}
                px={'3rem'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                bg={registerType === 'TUTOR' ? 'blue' : 'black'}
                color={registerType === 'TUTOR' ? 'white' : 'white'}
                rounded={
                  registerType === 'TUTOR'
                    ? '0px 10px 10px 0px'
                    : '10px 0px 0px 10px'
                }
                transition="all"
                transitionDuration="0.5s"
                fontSize={'22px'}
                fontWeight={700}
                onClick={() => {
                  setRegisterType('TUTOR');
                }}
              >
                Tutor
              </Button>
            </Box>

            <VStack spacing="20px" w="100%">
              <FormControl id="email">
                <Input
                  borderColor={errors.email ? '#E1526C' : 'white'}
                  rounded="md"
                  py={'1.8rem'}
                  color="white"
                  type="email"
                  placeholder="Email address"
                  {...register('email')}
                  _placeholder={{
                    color: 'white',
                    fontWeight: 700,
                    fontSize: '18px',
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.email && <p>{errors.email.message}</p>}
                </Box>
              </FormControl>

              <Box w="full">
                <Box display="flex" flexDirection="column" gap="6px">
                  <FormControl id="password" flex="1">
                    <InputGroup size="md">
                      <Input
                        borderColor={errors.password ? '#E1526C' : 'white'}
                        color="white"
                        rounded="md"
                        _focus={{
                          outline: 'none',
                          ringColor: 'white',
                        }}
                        _hover={{
                          borderColor: 'white',
                        }}
                        py={'1.8rem'}
                        type={show ? 'text' : 'password'}
                        placeholder="password"
                        {...register('password')}
                        _placeholder={{
                          color: 'white',
                          fontWeight: 700,
                          fontSize: '18px',
                        }}
                      />
                      <InputRightElement py={'1.8rem'} px={'6'}>
                        <Box size="sm" rounded="md" onClick={handleClick}>
                          {!show ? (
                            <AiOutlineEyeInvisible size={28} color="white" />
                          ) : (
                            <AiOutlineEye size={28} color="white" />
                          )}
                        </Box>
                      </InputRightElement>
                    </InputGroup>
                    <Box color="#E1526C" fontSize="14px" mt="5px">
                      {errors.password && <p>{errors.password.message}</p>}
                    </Box>
                  </FormControl>
                  <FormControl id="confirm_password">
                    <InputGroup size="md">
                      <Input
                        borderColor={
                          errors.confirm_password ? '#E1526C' : 'white'
                        }
                        _focus={{
                          outline: 'none',
                          ringColor: 'white',
                        }}
                        _hover={{
                          borderColor: 'white',
                        }}
                        py={'1.8rem'}
                        color="white"
                        rounded="md"
                        type={show ? 'text' : 'password'}
                        placeholder="Re-type password"
                        {...register('confirm_password')}
                        _placeholder={{
                          color: 'white',
                          fontWeight: 700,
                          fontSize: '18px',
                        }}
                      />
                      <InputRightElement py={'1.8rem'} px={'6'}>
                        <Box size="sm" rounded="md" onClick={handleClick}>
                          {!show ? (
                            <AiOutlineEyeInvisible size={28} color="white" />
                          ) : (
                            <AiOutlineEye size={28} color="white" />
                          )}
                        </Box>
                      </InputRightElement>
                    </InputGroup>
                    <Box color="#E1526C" fontSize="14px" mt="5px">
                      {errors.confirm_password && (
                        <p>{errors.confirm_password.message}</p>
                      )}
                    </Box>
                  </FormControl>
                </Box>
              </Box>
            </VStack>
            <VStack
              w="100%"
              mt={{ base: '15px', md: '15px' }}
              direction="column"
              gap="15px"
            >
              <Button
                bg={'blue'}
                color="white"
                rounded="10px"
                w="100%"
                py="1.7rem"
                fontSize="22px"
                fontWeight="600"
                type="submit"
                isLoading={isLoading}
              >
                CONTINUE
              </Button>
              <Box color="white" fontWeight="400" fontSize="14px">
                By creating an account, you agree to our
                <Link to="/" style={{ marginLeft: '5px' }}>
                  Privacy Policy
                </Link>
              </Box>
              <Box color="white" fontWeight="400" fontSize="14px" display="flex">
                Already have an account?
                <Link to="/login" style={{ marginLeft: '5px' }}>
                  <Text _hover={{ color: 'blue' }}>Login</Text>
                </Link>
              </Box>
            </VStack>
          </VStack>
        </Stack>
      </Center>
    </Box>
  );
};

export default RegisterDetails;
