import { Box, Heading } from '@chakra-ui/react';
import React from 'react';

export default function SettingsHeader() {
  return (
    <>
      <Box
        w="full"
        bg={{ base: 'blue', md: '#1D1D1D' }}
        color="white"
        py={{ base: '1rem', md: '3rem' }}
        display={'flex'}
        justifyContent={'center'}
      >
        <Heading color="white" fontWeight="600" fontSize="40px">
          Settings
        </Heading>
      </Box>
    </>
  );
}
