import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { useLocation } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { enGB } from 'date-fns/locale';
import CustomToolbar from './CustomToolbar';
import CustomDayHeader from './weekview_header';
import EventModal from './EventModal';
import EditBooking from '../FromSidebar/Shared/EditBooking';
import './calender.css';
import {
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import CalenderHeader from '../Headers/CalenderHeader';
import { API_BASE_URL } from '../../apiPaths';
import RequestActionModal from './RequestActionModal';

const locales = {
  'en-GB': enGB,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { locale: enGB }),
  getDay,
  locales,
});

const MyCalendar = (props) => {
  const toast = useToast();
  const location = useLocation();
  const [view, setView] = useState(() => {
    return localStorage.getItem('view') || 'month';
  });
  const [showModal, setShowModal] = useState(false);
  const [cancelBookingSession, setCancelBookingSession] = useState(false);
  const [showRequestActionModal, setShowRequestActionModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [myServices, setMyServices] = useState([]);
  const [tutorSettings, setTutorSettings] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const [isEditBooking, setIsEditBooking] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  useEffect(() => {
    // Function to handle screen size change
    const updateViewBasedOnScreenSize = () => {
      if (window.innerWidth <= 768) {  // Adjust the breakpoint as needed
        setView('week');
      } else {
        setView(localStorage.getItem('view') || 'month');
      }
    };
  
    // Set the view on component mount
    updateViewBasedOnScreenSize();
  
    // Listen to window resize event
    window.addEventListener('resize', updateViewBasedOnScreenSize);
  
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateViewBasedOnScreenSize);
    };
  }, []); 

  const params = new URLSearchParams(location.search);
  const isConfirm = params.get('isconfirm') === 'true';
  const isModify = params.get('ismodify') === 'true';
  const isCancel = params.get('iscancel') === 'true';
  const isEmail = params.get('isemail') === 'true';
  const requestID = params.get('rid');

  function handleFormClose(val) {
    setIsEditBooking(val);
  }

  const formatTime = (dateString) => {
    return format(new Date(dateString), 'HH:mm');
  };

  const handleViewChange = (view) => {
    localStorage.setItem('view', view);
    setView(view);
  };

  const dayPropGetter = (date) => {
    const today = new Date();
    const isPast = date < today;

    if (date.toDateString() === today.toDateString()) {
      return {
        style: {
          backgroundColor: '#252525',
          borderRadius: '10px',
        },
      };
    } else if (isPast) {
      return {
        style: {
          backgroundColor: '#000',
          borderRadius: '10px',
          border: 'none',
          margin: '2px',
          color: 'grey',
        },
      };
    } else {
      return {
        style: {
          backgroundColor: '#252525',
          borderRadius: '10px',
          border: 'none',
          margin: '2px',
        },
      };
    }
  };

  const eventPropGetter = (event) => {
    const isNarrowScreen = window.innerWidth <= 600;
    let newStyle = {
      color: 'black',
      borderRadius: isNarrowScreen ? '20px' : '5px',
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      border: '2px solid black',
      color: 'white',
    };

    if (event.isBooked === 'y') {
      const today = new Date();
      if (new Date(event.end) < today) newStyle.background = '#002299';
      else newStyle.background = '#0033ff';
    } else {
      newStyle.background = '#0033ff';
    }

    return {
      style: newStyle,
    };
  };

  const handleEventSelect = (event) => {
    const selectedDateStart = new Date(event.start);
    const today = new Date();
    const isPast = selectedDateStart < today;
    if (event.isBooked == 'y' && isPast) return;
    if (event?.isBooked == 'y') {
      event.isCalendarBooking = true;
      setSelectedBooking(event);
      setIsEditBooking(true);
      return;
    }
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleSlotSelect = (slotInfo) => {
    setSelectedEvent(null);

    const selectedDateStart = new Date(slotInfo.start);
    const selectedDateEnd = new Date(slotInfo.end);

    const today = new Date();
    const isPast = selectedDateStart < today;
    if (isPast) return;

    if (view === 'week') {
      setSelectedSlot({ start: selectedDateStart, end: selectedDateEnd });
    } else {
      const startOfWorkday = new Date(selectedDateStart.setHours(9, 0, 0, 0));
      const endOfWorkday = new Date(selectedDateStart.setHours(17, 0, 0, 0));
      setSelectedSlot({ start: startOfWorkday, end: endOfWorkday });
    }

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowRequestActionModal(false);
  };

  async function fetchData() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/session/${currentUser.id}`,
        {
          headers: {
            'x-access-token': currentUser.token,
          },
        }
      );
      const sessions = await response.json();

      // Get the current date and time
      const now = new Date();
      const startOfToday = new Date(now.setHours(0, 0, 0, 0));

      const filteredEvents = sessions.data.filter((event) => {
        if (event.isBooked === 'y') {
          return true;
        }
        const eventEnd = new Date(event.end);
        return eventEnd > startOfToday;
      });

      const eventsWithDates = filteredEvents.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));

      setEvents(eventsWithDates);

      const serviceResponse = await fetch(
        `${API_BASE_URL}/service-builder/${currentUser.id}`,
        {
          headers: {
            'x-access-token': currentUser.token,
          },
        }
      );
      const services = await serviceResponse.json();
      setMyServices(services.data);

      const tutorSettingsResponse = await fetch(
        `${API_BASE_URL}/tutor-settings/${currentUser.id}`,
        {
          headers: {
            'x-access-token': currentUser.token,
          },
        }
      );
      const tutorSettingsData = await tutorSettingsResponse.json();
      setTutorSettings(tutorSettingsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (currentUser.roleName !== 'TUTOR') {
      return;
    }

    if (isEmail && !showRequestActionModal) {
      if (currentUser?.roleName === 'TUTOR')
        localStorage.setItem('selectedButton', 'calendar');
      else localStorage.setItem('selectedButton', '');
      setShowRequestActionModal(true);
    }

    fetchData();
  }, [isEmail, showRequestActionModal]);

  const handleFormSubmit = async (formData) => {
    let newEvents = [];
    const eventToAdd = { ...formData, id: Math.random() };
    newEvents.push(eventToAdd);

    const currentYear = new Date(formData.start).getFullYear();
    const endTime =
      new Date(formData.end).getTime() - new Date(formData.start).getTime();

    let currentDate = new Date(formData.start);
    const yearOfStartDate = new Date(formData.start).getFullYear();

    const getNextDate = {
      'Every day': (date) => addDaySkippingWeekends(date, yearOfStartDate),
      'Every Week': (date) => addWeekSkippingWeekends(date, yearOfStartDate),
      'Every Fortnight': (date) =>
        addFortnightSkippingWeekends(date, yearOfStartDate),
      'Every Week Day': (date) => addWeekday(date, yearOfStartDate),
    };

    while (
      formData.repeat &&
      getNextDate[formData.repeat] &&
      currentDate.getFullYear() === yearOfStartDate
    ) {
      currentDate = getNextDate[formData.repeat](currentDate);
      if (!currentDate) {
        break;
      }
      if (currentDate.getFullYear() !== yearOfStartDate) {
        break;
      }
      newEvents.push({
        ...formData,
        id: Math.random(),
        start: currentDate,
        end: new Date(currentDate.getTime() + endTime),
      });
    }

    const response = await fetch(`${API_BASE_URL}/session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': currentUser.token,
      },
      body: JSON.stringify(newEvents),
    });

    if (!response.ok) {
      toast({
        title: 'Error saving sessions.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw new Error('Network response was not ok');
    } else {
      fetchData();
      toast({
        title: 'Sessions registered successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }

    setSelectedEvent(null);
    setShowModal(false);
  };

  const addDaySkippingWeekends = (date) => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    while (nextDate.getDay() === 0 || nextDate.getDay() === 6) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
    return nextDate;
  };

  const addWeekday = (date) => {
    const nextDate = new Date(date.setDate(date.getDate() + 1));
    while (nextDate.getDay() === 0 || nextDate.getDay() === 6) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
    return nextDate;
  };

  const addWeekSkippingWeekends = (date) => {
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 7);
    while (nextDate.getDay() === 0 || nextDate.getDay() === 6) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
    return nextDate;
  };

  const addFortnightSkippingWeekends = (date, targetYear) => {
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 14);
    while (nextDate.getDay() === 0 || nextDate.getDay() === 6) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
    if (nextDate.getFullYear() > targetYear) {
      return null;
    }
    return nextDate;
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/session/delete/${eventId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': currentUser.token,
          },
        }
      );

      if (!response.ok) {
        toast({
          title: 'Error saving session.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        throw new Error('Network response was not ok');
      } else {
        fetchData();
        toast({
          title: 'Session deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting session:', error);
    }
  };

  const handleUpdateEvent = async (event) => {
    const endTime =
      new Date(event.end).getTime() - new Date(event.start).getTime();

    let currentDate = new Date(event.start);

    const eventToUpdate = {
      ...event,
      start: currentDate,
      end: new Date(currentDate.getTime() + endTime),
    };

    const response = await fetch(`${API_BASE_URL}/session/${event?.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': currentUser.token,
      },
      body: JSON.stringify(eventToUpdate),
    });

    if (!response.ok) {
      toast({
        title: 'Error update session.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw new Error('Network response was not ok');
    } else {
      fetchData();
      toast({
        title: 'Sessions updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }

    setSelectedEvent(null);
    setShowModal(false);
  };

  const CustomEvent = ({ event }) => {
    return (
      <Box>
        <Box>
          {view === 'month' ? (
            event?.serviceId?.name?.toLowerCase() === 'one to one session' ? (
              <Box>
                {`${formatTime(event?.start)} ${event?.totalTime}`}
                <Box display="block">{event?.bookedBy?.displayName}</Box>
              </Box>
            ) : (
              <>
                {`${formatTime(event?.start)} ${event?.totalTime}`}
                <Box>{event?.serviceId?.name}</Box>
                <Box display="block">{event?.bookedBy?.displayName}</Box>
              </>
            )
          ) : event?.serviceId?.name?.toLowerCase() === 'one to one session' ? (
            ''
          ) : (
            <>
              {`${formatTime(event?.start)} ${event?.totalTime}`}
              <Box display="block">{event?.serviceId?.name}</Box>
              <Box display="block">{event?.bookedBy?.displayName}</Box>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const convertTimeStringToDate = (timeString) => {
    const [hours, minutes] = timeString?.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  const minTimeString = currentUser?.settings?.earliestStartTime;
  const maxTimeString = currentUser?.settings?.latestFinishTime;

  const minTime = convertTimeStringToDate(minTimeString);
  const maxTime = convertTimeStringToDate(maxTimeString);

  return (
    <>
      <Box bg="grey" color="white" rounded={'10px'}>
        <Calendar
          localizer={localizer}
          min={minTime}
          max={maxTime}
          events={events}
          dayPropGetter={dayPropGetter}
          eventPropGetter={eventPropGetter}
          startAccessor={(event) => new Date(event.start)}
          endAccessor={(event) => new Date(event.end)}
          view={view}
          style={{ height: `${window.innerHeight}px` }}
          views={['month', 'week', 'day']}
          onView={handleViewChange}
          onSelectSlot={handleSlotSelect}
          onSelectEvent={handleEventSelect}
          selectable
          components={{
            toolbar: (props) => (
              <CustomToolbar
                {...props}
                view={view}
                profile={currentUser.profile}
                displayName={currentUser.displayName}
              />
            ),
            event: CustomEvent,
            week: { header: CustomDayHeader },
          }}
        />
      </Box>
      {showModal && (
        <EventModal
          isOpen={showModal}
          onClose={handleCloseModal}
          onSubmit={handleFormSubmit}
          selectedSlot={selectedSlot}
          view={view}
          event={selectedEvent}
          onDelete={handleDeleteEvent}
          onUpdate={handleUpdateEvent}
          services={myServices}
          events={events}
        />
      )}
      {showRequestActionModal && (
        <RequestActionModal
          isOpen={showRequestActionModal}
          confirm={isConfirm}
          modify={isModify}
          cancel={isCancel}
          requestID={requestID}
          onClose={handleCloseModal}
        />
      )}
      {isEditBooking && (
        <EditBooking
          onFormClose={handleFormClose}
          tutorId={selectedBooking?.userId}
          selectedBooking={selectedBooking}
          fetchBookings={fetchData}
          bookingId={selectedBooking._id}
          onDeleteSuccess={fetchData}
        />
      )}
    </>
  );
};

export default MyCalendar;
