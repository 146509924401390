import React, { useState } from 'react';
import SettingsHeader from '../Components/Headers/SettingsHeader';
import {
  FormControl,
  Input,
  Heading,
  VStack,
  FormLabel,
  Stack,
  Center,
  Switch,
  InputGroup,
  Button,
  Box,
  useToast,
  FormErrorMessage,
  useColorModeValue,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { API_BASE_URL } from '../apiPaths';
import bgImage from '../assests/ww.png';
const settingsSchema = yup.object().shape({
  earliestStartTime: yup.string().required('Earliest start time is required'),
  latestFinishTime: yup.string().required('Latest finish time is required'),
  flatRate: yup.string().required('Flat rate is required'),
});

function Setting() {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const toast = useToast();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(settingsSchema),
    defaultValues: {
      flatRate: currentUser?.flatRate || '',
    },
  });

  // Watch for flatRate and ensure it's updated correctly
  const flatRate = watch('flatRate');

  const handleSwitchChange = (e) => {
    setIsSwitchOn(e.target.checked);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/tutor-setting/rate/${currentUser.id}`,
        {
          method: 'PUT',
          headers: {
            'x-access-token': currentUser.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        toast({
          title: 'Failed to update flat rate.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
        throw new Error('Network response was not ok');
      } else {
        const updatedUser = {
          ...currentUser,
          flatRate: Number(data.flatRate),
          settings: {
            earliestStartTime: data.earliestStartTime,
            latestFinishTime: data.latestFinishTime,
          },
        };
        localStorage.setItem('currentUser', JSON.stringify(updatedUser));
        toast({
          title: 'Settings updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error('There has been a problem with your operation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box height={'100vh'} overflow={{ md: 'hidden' }}>
      <Box pos={{ md: 'fixed' }} w={{ md: '90%' }} right={0}>
        <SettingsHeader />
      </Box>
      <Center
        mt={{ md: '5rem' }}
        background={`url(${bgImage})`}
        backgroundSize={{ base: 'cover', lg: 'cover' }}
        backgroundPosition={{ base: 'right', lg: 'top' }}
        backgroundRepeat="no-repeat"
        display={'flex'}
        justifyContent={'center'}
        alignItems={{ lg: 'center' }}
        height={'100%'}
      >
        <Stack width={{ base: '90%', md: '28%' }} mt={{ base: '5rem' }}>
          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            bg={useColorModeValue('white', 'black')}
            color="white"
            position="relative"
            borderTopRadius={{ base: '16px', md: 'none' }}
            rounded={{ base: '30px', md: '30px' }}
            px={{ base: '15px', md: '24px' }}
            py={{ base: '40px', md: '32px' }}
          >
            <FormControl
              display={'flex'}
              flexDirection={'column'}
              alignContent={'start'}
              justifyContent={'start'}
              isInvalid={errors.earliestStartTime}
            >
              <FormLabel fontSize={'1.2rem'}>
                Your Calendar Day Starts From
              </FormLabel>

              <Input
                mt={'-.7rem'}
                type="time"
                border="none"
                color="#0033ff"
                fontWeight="800"
                fontSize={'3.5rem'}
                height="unset"
                variant={'unstyled'}
                {...register('earliestStartTime')}
                defaultValue={
                  currentUser?.settings?.earliestStartTime.split(' ')[0] ||
                  '09:00'
                }
              />
              <FormErrorMessage>
                {errors.earliestStartTime?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={1} isInvalid={errors.latestFinishTime}>
              <FormLabel fontSize={'1.2rem'}>
                Your Calendar Day Finishes At
              </FormLabel>
              <Input
                mt={'-.7rem'}
                variant={'unstyled'}
                type="time"
                border="none"
                color="#0033ff"
                fontWeight="800"
                height="unset"
                fontSize={'3.5rem'}
                {...register('latestFinishTime')}
                defaultValue={
                  currentUser?.settings?.latestFinishTime || '17:00'
                }
              />
              <FormErrorMessage>
                {errors.latestFinishTime?.message}
              </FormErrorMessage>
            </FormControl>

            <VStack spacing={{ base: '15px', md: '15px' }} w="100%">
              <FormControl>
                <FormLabel fontSize={'1.2rem'}>Flat Rate</FormLabel>
                <Switch
                  id="rate-setting"
                  size="md"
                  onChange={handleSwitchChange}
                  sx={{
                    '& .MuiSwitch-switchBase': {
                      color: 'grey',
                      '&.Mui-checked': {
                        color: 'blue ',
                      },
                    },
                  }}
                />
              </FormControl>
              {!isSwitchOn && (
                <FormControl mt="2" id="flatRate">
                  <FormLabel fontSize={'1.2rem'}>
                    Your flat rate per hour
                  </FormLabel>
                  <InputGroup>
                    <Box
                      as="span"
                      my="auto"
                      fontSize={'2.5rem'}
                      className="euro-sign"
                    >
                      £
                    </Box>
                    <Input
                      mt={'-.8rem'}
                      variant={'unstyled'}
                      className="rate-per-hour"
                      type="text"
                      border="none"
                      color="#0033ff"
                      fontWeight="800"
                      height="unset"
                      padding="0"
                      fontSize={'3.5rem'}
                      defaultValue={`${currentUser?.flatRate}`}
                      {...register('flatRate')}
                    />
                    <Box color="#E1526C" fontSize="14px" mt="5px">
                      {errors.flatRate && <p>{errors.flatRate.message}</p>}
                    </Box>
                  </InputGroup>
                </FormControl>
              )}
            </VStack>
            <Box
              w="30%"
              mt={'30px'}
              display={'flex '}
              justifyContent={'start'}
              alignItems={'start'}
              mr={'auto'}
            >
              <Button
                color="white"
                rounded="3px"
                w="100%"
                bg="#0033ff"
                _hover={{
                  bg: '#0033ff',
                }}
                py="1.2rem"
                fontSize="22px"
                fontWeight="500"
                type="submit"
                isLoading={isLoading}
                isDisabled={!flatRate}
              >
                Save
              </Button>
            </Box>
          </VStack>
        </Stack>
      </Center>
    </Box>
  );
}

export default Setting;
