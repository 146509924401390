import { useState } from 'react';
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  InputRightElement,
  Box,
  useToast,
  Img,
  Text,
} from '@chakra-ui/react';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';

import { API_BASE_URL } from '../apiPaths';
import bgImage from '../assests/22.png';
import logo from '../assests/Logo.png';

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const sessionRequestFormData = JSON.parse(
    localStorage.getItem('sessionRequestFormData')
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => setShow(!show);
  const users = [
    {
      email: 'admin@admin.com',
      password: 'admin123',
      role: 'TUTOR',
    },
    {
      email: 'user@user.com',
      password: 'user123',
      role: 'STUDENT',
    },
  ];
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm();
  const watchEmail = watch('email', '');
  const watchPassword = watch('password', '');

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!data.email) {
      setError('email', {
        type: 'manual',
        message: 'email not recognised',
      });
    }
    if (!data.password) {
      setError('password', {
        type: 'manual',
        message: 'password incorrect',
      });
    }

    try {
      const response = await fetch(`${API_BASE_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const jsonResponse = await response.json();

      if (!response.ok) {
        toast({
          title: `${jsonResponse.data.error}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        throw new Error(jsonResponse.message);
      }

      localStorage.setItem('currentUser', JSON.stringify(jsonResponse.data));

      if (jsonResponse?.data?.roleName === 'TUTOR')
        localStorage.setItem('selectedButton', 'calendar');
      else localStorage.setItem('selectedButton', 'dashboard');
      toast({
        title: 'Login success',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      if (jsonResponse.data.roleName === 'STUDENT') navigate('/tutor-search');
      else navigate('/calendar');

      if (sessionRequestFormData && sessionRequestFormData?.url) {
        navigate(sessionRequestFormData.url);
      } else {
        if (jsonResponse.data.roleName === 'STUDENT') navigate('/tutor-search');
        else navigate('/calendar');
      }
    } catch (error) {
      console.error(
        'There has been a problem with your fetch operation:',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isLoginFormValid = () => {
    return !watchEmail || watchPassword.length < 6;
  };

  return (
    <Box
      position="relative"
      background={`url(${bgImage})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition={'start'}
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box color="#fff" fontWeight="600" width={'90%'} mx={'auto'} pt={'10'}>
        <Img
          src={logo}
          h={{ base: '4rem', lg: '5rem' }}
          w={{ base: '10rem', lg: '15rem' }}
        />
      </Box>

      <Box display={'flex'} pos={'relative'} bottom={'5rem'}>
        <Stack
          minW={{ base: '100%', md: '700px' }}
          spacing={12}
          marginLeft="auto"
        >
          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            width={'100%'}
            maxWidth={{ base: '400px', md: 'none' }}
            mx="auto"
            rounded={{ base: '', md: 'lg' }}
            px={{ base: '10px', md: '140px' }}
            py={{ base: '10px', md: '60px' }}
          >
            <Stack align="center" mb="30px">
              <Heading
                fontSize={{ base: '28px', md: '33px' }}
                fontWeight="900"
                color="white"
              >
                Sign In
              </Heading>
            </Stack>

            <VStack spacing={4} w="100%">
              <FormControl id="email">
                <Input
                  borderRadius="0"
                  borderColor={errors.email ? '#E1526C' : '#fff'}
                  _hover={{
                    borderColor: '#fff',
                  }}
                  color="white"
                  type="email"
                  py="28px"
                  rounded={'10'}
                  placeholder="Email Address"
                  {...register('email')}
                  _placeholder={{
                    color: '#D2D2D2',
                    fontSize: { base: '16px', md: '18px' },
                  }}
                />
                <Box color="#E1526C" fontSize="16px" mt="5px">
                  {errors.email && <p>{errors.email.message}</p>}
                </Box>
              </FormControl>
              <FormControl id="password">
                <InputGroup size="md">
                  <Input
                    // bg="#2F2F2F"
                    borderRadius="0"
                    border="2px"
                    borderColor={errors.password ? '#E1526C' : '#fff'}
                    color="white"
                    py="28px"
                    rounded={'10'}
                    _hover={{
                      borderColor: '#fff',
                    }}
                    type={show ? 'text' : 'password'}
                    placeholder="password"
                    {...register('password')}
                    _placeholder={{
                      color: '#D2D2D2',
                      fontSize: { base: '12px', md: '18px' },
                    }}
                  />
                  <InputRightElement>
                    <Box
                      size="sm"
                      rounded="md"
                      pt={'4'}
                      pr={'2'}
                      onClick={handleClick}
                    >
                      {!show ? (
                        <AiOutlineEyeInvisible size={30} color="white" />
                      ) : (
                        <AiOutlineEye size={30} color="#fff" />
                      )}
                    </Box>
                  </InputRightElement>
                </InputGroup>
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.password && <p>{errors.password.message}</p>}
                </Box>
              </FormControl>
            </VStack>
            <Stack direction="row" justify="end" w="100%" color="#fff">
              <Link color="#fff" style={{ fontSize: '18px' }}>
                Forgot password?
              </Link>
            </Stack>
            <VStack w="100%" mt="30px" direction="column" gap="15px">
              <Button
                bg={'#0033ff'}
                color="white"
                borderRadius="0"
                rounded="10px"
                w="100%"
                py="28px"
                fontSize="24px"
                fontWeight="600"
                type="submit"
                // isDisabled={isLoginFormValid()}
                isLoading={isLoading}
              >
                Log in
              </Button>
              <Text
                color={'white'}
                fontSize={'14px'}
                textAlign={'center'}
                display="flex"
                justifyContent="center"
                style={{ width: '100%' }}
                >
                Don't have an account?
                <Link to="/register">
                  <Text
                    marginLeft="5px"
                    _hover={{ color: 'blue' }}
                  >
                   Register
                  </Text>
                </Link>
              </Text>
            </VStack>
          </VStack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Login;
