import { HamburgerIcon } from '@chakra-ui/icons';
import { Box, Img } from '@chakra-ui/react';
import React from 'react';
import logo from '../../assests/Logo.png';

const SmallHeader = ({ handleToggle }) => {
  return (
    <Box py={'1rem'} bgColor={'#1D1D1D'}>
      <Box
        display={'flex '}
        width={'67%'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mr={'auto'}
      >
        <div
          onClick={handleToggle}
          style={{ position: 'relative', left: '1rem', cursor: 'pointer' }}
        >
          <HamburgerIcon h={10} w={10} />
        </div>
        <Img height={'3rem'} src={logo} />{' '}
      </Box>
    </Box>
  );
};

export default SmallHeader;
